import { getSession, signIn, SignInResponse } from 'next-auth/react';
import { Dispatch, FormEventHandler, MouseEventHandler, SetStateAction, useState } from 'react';
import Head from 'next/head';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await getSession(context);
  if (session) {
    // user is already logged in, redirect them
    return {
      redirect: {
        destination: `/`,
        permanent: false,
      },
    };
  }
  return { props: {} };
};

const SignIn: React.FC<InferGetServerSidePropsType<typeof getServerSideProps>> = () => {
  const emailState = useState('');
  const [email] = emailState;

  // TODO: some error handling on sign in
  const [signInResponse, setSignInResponse] = useState<SignInResponse>();
  return (
    <>
      <Head>
        <title>UniHelper Portal - Sign in</title>
      </Head>
      <div className="flex justify-center flex-grow items-center">
        <div className="flex flex-col items-center rounded-md p-3 space-y-3 shadow-lg border max-w-xs w-full bg-white">
          <div className="w-1/2">{unihelperSvgLogo}</div>
          <h1 className="py-3 text-2xl">UniHelper Portal</h1>
          {!signInResponse ? (
            <SignInForm
              emailState={emailState}
              onSubmit={async (event) => {
                event.preventDefault();
                const response = await signIn('email', {
                  email,
                  redirect: false,
                  // return to the base location without any paths
                  callbackUrl: window?.location.origin,
                })!;
                setSignInResponse(response);
              }}
            />
          ) : (
            <Verify email={email} onNoEmailClick={() => setSignInResponse(undefined)} />
          )}
        </div>
      </div>
    </>
  );
};

type SignInFormProps = {
  onSubmit: FormEventHandler<HTMLFormElement>;
  emailState: [string, Dispatch<SetStateAction<string>>];
};
const SignInForm: React.FC<SignInFormProps> = ({ onSubmit, emailState }) => {
  const [email, setEmail] = emailState;
  return (
    <form onSubmit={onSubmit} className="flex flex-col items-stretch space-y-3 w-full">
      <label className="flex flex-col space-y-3">
        <span className="self-center">Enter your e-mail to sign in</span>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="name@institution.com"
          className="rounded-md border border-blue-300 py-1 px-2"
        />
      </label>
      <button
        type="submit"
        className="rounded-md bg-cta-pink hover:bg-cta-pink-light transition-colors text-white py-1 px-3 self-stretch"
      >
        Sign in
      </button>
    </form>
  );
};

type VerifyProps = {
  email: string;
  onNoEmailClick: MouseEventHandler<HTMLButtonElement>;
};

const Verify: React.FC<VerifyProps> = ({ email, onNoEmailClick }) => (
  <>
    <span>
      Please check your inbox. An e-mail has been sent to <b>{email}</b> with a button to sign in.
    </span>
    <button onClick={onNoEmailClick} className="text-blue-600 underline">
      Haven't received the e-mail?
    </button>
  </>
);

const unihelperSvgLogo = (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 800 733">
    <defs />
    <path
      fill="#E6F6FF"
      fillRule="evenodd"
      d="M13.5 301.3c-107 353.5 450.7 550.8 674 353.5 223.4-197.3 77.7-524.1-140-605.3-217.8-81.1-426.9-101.8-534 251.8z"
      clipRule="evenodd"
    />
    <path
      fill="#2186EB"
      stroke="#E6F6FF"
      strokeWidth="14.4"
      d="M54.2 402.5C54.2 335 109 281 176.7 281H190c67.9 0 122.5 52.4 122.5 120 0 3.9-.8 8.5-2.4 12.5-1.7 4.2-4 7.3-6.5 9-31 21.9-75.3 33-120.2 33-44.9 0-89.2-11.1-120.1-33-5.6-3.8-9-11.8-9-20z"
    />
    <circle cx="183.3" cy="219.7" r="87.3" fill="#2186EB" stroke="#E6F6FF" strokeWidth="14.4" />
    <path
      fill="#2186EB"
      stroke="#E6F6FF"
      strokeWidth="14.4"
      d="M437.8 404c0-67.6 54.8-121.5 122.5-121.5h13.2c67.8 0 122.5 52.4 122.5 119.9 0 4-.8 8.5-2.5 12.6s-4 7.3-6.5 9c-30.8 21.8-75.2 33-120.1 33-45 0-89.3-11.2-120.2-33-5.5-3.9-9-11.8-9-20z"
    />
    <circle cx="566.9" cy="221.1" r="87.3" fill="#2186EB" stroke="#E6F6FF" strokeWidth="14.4" />
    <path
      fill="#2186EB"
      stroke="#E6F6FF"
      strokeWidth="23.1"
      d="M176.7 566c0-101 81.9-181 183.3-181h30.2c101.6 0 183.3 77.4 183.3 178.2 0 7.4-1.6 15.9-4.7 23.5a36.3 36.3 0 01-12.2 16.8c-47.3 31.8-114 48.3-181.5 48.3s-134.2-16.5-181.5-48.3L187 613l6.5-9.5c-10.5-7.1-16.9-22.3-16.9-37.5z"
    />
    <path
      fill="#2186EB"
      stroke="#E6F6FF"
      strokeWidth="23.1"
      d="M509 263c0 73.6-59.9 133.3-133.9 133.3s-134-59.7-134-133.4c0-73.6 60-133.4 134-133.4s134 59.8 134 133.4z"
    />
  </svg>
);

export default SignIn;
